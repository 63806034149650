import '../styles/styles.css';

import * as React from 'react';
import { Helmet } from 'react-helmet';

import Evento from '../components/evento.component';
import eventos from '../data/eventos.data';
import { mainDescription, mainSubtitle, mainTitle, mainUrl } from '../data/main.data';
import cartaz from '../images/cartaz.jpg';
import imageEmpregoConcello from '../images/emprego_concello.png';
import favicon from '../images/favicon.jpg';
import logos from '../images/logos.png';

const styles = {
  emprego: {
    backgroundImage: `url(${imageEmpregoConcello})`,
    backgroundRepeat: "no-repeat",
    backgroundSize: "contain",
    backgroundPosition: "center",
    margin: "0 auto",
    height: "150px",
    width: "150px",
  },
}

// markup
const IndexPage = () => {
  return (
    <main id="sementeira" lang="gl-ES">
      <Helmet>
        <meta charSet="utf-8" lang="gl-ES" />
        <title>
          {mainTitle} | {mainSubtitle}:{" "}
          {eventos.map(({ parroquia }) => parroquia.nome).join(", ")}
        </title>
        <link rel="canonical" href={mainUrl} />
        <link rel="shortcut icon" type="image/jpg" href={favicon} />
        <script
          defer
          src="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.14.0/js/brands.js"
        ></script>
        <script
          defer
          src="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.14.0/js/fontawesome.js"
        ></script>
      </Helmet>
      <header>
        <h1>
          <a href={mainUrl}>{mainTitle}</a>
        </h1>
        <h2>{mainSubtitle}</h2>
        <ul className="nav">
          {eventos.map(({ parroquia }) => {
            return (
              <li key={parroquia.id || parroquia.nome}>
                <a href={"#" + (parroquia.id || parroquia.nome)}>
                  {parroquia.nome}
                </a>
              </li>
            )
          })}
        </ul>
      </header>
      <div className="presentacion">
        <div className="left">
          <h4>NOVOS XEITOS DE ACHEGARSE AO CAMIÑO DE SANTIAGO</h4>
          <p>
            O Camiño de Santiago é, desde fai séculos, unha das rutas de
            peregrinación máis importantes do mundo, cun inmenso impacto que se
            estende, como non podía ser doutro xeito á arte e a cultura.
            Sementeira no Camiño pretende salientar a riqueza do patrimonio
            natural e cultural do Camiño de Santiago, así como o seu aspecto
            humano ao buscar o intercambio de experiencias entre os seus
            habitantes e peregrinos a través da arte. Neste ano Xacobeo,
            achegamos a distintas vilas do Camiño Portugués outro xeito de vivir
            e sentir esta peregrinación. Durante cinco días poñerase de
            manifesto os valores e bondades do Camiño de Santiago a través da
            proxección dunha serie de filmes aderezados coa fermosa poesía de
            artistas das vilas. Iria Flavia, Pontecesures, Cesantes, Redondela e
            Tui serán os puntos de descanso neste especial Camiño.
          </p>
        </div>
        <div className="right">
          <img src={cartaz} alt={mainDescription} />
        </div>
      </div>
      <div style={{ clear: "both" }}>
        {eventos.map(({ parroquia, filme, poeta }) => {
          return (
            <Evento
              key={parroquia.nome}
              parroquia={parroquia}
              filme={filme}
              poeta={poeta}
            ></Evento>
          )
        })}
      </div>
      <footer>
        <div className="logos">
          <img src={logos} alt="Colaboradores" />
          <div style={styles.emprego}></div>
        </div>
        <ul className="social">
          <li>
            <a href="https://www.facebook.com/somosabella">
              <i class="fab fa-facebook-square" title="Facebook"></i>
            </a>
          </li>
          <li>
            <a href="https://www.instagram.com/somos.abella">
              <i class="fab fa-instagram-square" title="Instagram"></i>
            </a>
          </li>
        </ul>
        <h3>
          {mainTitle} | {mainSubtitle} | {mainDescription}
        </h3>
      </footer>
      <div className="nav-to-top">
        <a href="#sementeira">⇧ Volver</a>
      </div>
    </main>
  )
}

export default IndexPage
