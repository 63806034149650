import * as React from 'react';

import Filme from './filme.component';
import Parroquia from './parroquia.component';
import Poeta from './poeta.component';

const Evento = ({ parroquia, filme, poeta }) => {
  return (
    <article id={parroquia.id || parroquia.nome} className="evento">
      <div className="left">
        <Parroquia {...parroquia}></Parroquia>
      </div>
      <div className="right">
        <Filme {...filme}></Filme>
        <Poeta {...poeta}></Poeta>
      </div>
    </article>
  )
}

export default Evento
