import * as React from 'react';

const Filme = ({
  titulo,
  director,
  ano,
  duracion,
  xenero,
  pais,
  premios,
  trailer,
  sinopse,
  poster,
}) => {
  return (
    <div className="filme">
      <h1>
        {titulo}
        <span>{xenero}</span>
      </h1>
      {poster && <img src={poster} alt={titulo} />}
      <h2>
        {pais} ({ano}) / {director} / {duracion} min
      </h2>
      <p>{sinopse}</p>
      {premios && (
        <ul>
          {premios.map((premio) => {
            return <li key={premio}>{premio}</li>
          })}
        </ul>
      )}
      {trailer && (
        <div className="trailer">
          <a href={trailer} target="_blank" rel="noreferrer">
            Ver trailer
          </a>
        </div>
      )}
    </div>
  )
}

export default Filme
