export const tui = {
  nome: "Tui",
  concello: "Tui",
  data: "Domingo 13 de xuño",
  hora: "20:00",
  local: "UNED Tui",
  entradas:
    "Entrada de balde con RESERVA PREVIA no correo actividades@abella.gal",
}

export const cesantes = {
  nome: "Cesantes",
  concello: "Redondela",
  data: "Sábado 12 de xuño",
  hora: "20:00",
  local: "Círculo Cultural Deportivo de Cesantes",
  entradas: "Entrada de balde ata completar aforo",
}

export const pontecesures = {
  nome: "Pontecesures",
  concello: "Pontecesures",
  data: "Venres 11 de xuño",
  hora: "19:00",
  local: "Plaza de Abastos",
  entradas: "Entrada de balde ata completar aforo",
}

export const redondela = {
  nome: "Redondela",
  concello: "Redondela",
  data: "Xoves 10 de xuño",
  hora: "20:00",
  local: "Redondela Multiusos",
  entradas: "Entrada de balde ata completar aforo",
}

export const iriaFlavia = {
  id: "IriaFlavia",
  nome: "Iria Flavia",
  concello: "Padrón",
  data: "Mércores 9 de xuño",
  hora: "19:00",
  local: "Museo Camilo José Cela",
  entradas: "Entrada de balde ata completar aforo",
}
