import alfinaldelcaminoPoster from '../images/filmes/alfinaldelcamino.jpg';
import footprintsPoster from '../images/filmes/footprints.jpg';
import oapostoloPoster from '../images/filmes/oapostolo.jpg';
import thewayPoster from '../images/filmes/theway.jpg';
import vialacteaPoster from '../images/filmes/vialactea.jpg';

export const viaLactea = {
  titulo: "La Vía Láctea",
  director: "Luis Buñuel",
  ano: 1969,
  duracion: 98,
  xenero: "Comedia",
  pais: "Francia",
  premios: ["Festival de Berlín: Premio Interfilm."],
  trailer: "https://www.youtube.com/watch?v=jONXQSSMjIE",
  sinopse:
    "Dous trotamundos franceses que, desde as aforas de París, deciden ir de peregrinaxe a Santiago de Compostela, coñecen numerosos personaxes e viven situacións estreitamente vinculadas ás crenzas relixiosas.",
  poster: vialacteaPoster,
}

export const oCamino = {
  titulo: "The Way (O Camiño)",
  director: "Emilio Estévez",
  ano: 2012,
  duracion: 120,
  xenero: "Drama",
  pais: "España",
  trailer: "https://www.youtube.com/watch?v=_bOx3o0jSoU",
  sinopse:
    "Un día, a Tom Avery (Martín Sheen), un reputado oftalmólogo viúvo, comunícanlle que o seu fillo Daniel, co que nunca tivo boas relacións, morreu nos Pireneos durante un temporal. Tom, abatido, viaxa a Francia e, cando pescuda que o seu fillo estaba a facer o Camiño de Santiago, decide terminar o roteiro na súa honra.",
  poster: thewayPoster,
}

export const alFinalDelCamino = {
  titulo: "Al final del Camiño",
  director: "Roberto Santiago",
  ano: 2009,
  duracion: 100,
  xenero: "Comedia",
  pais: "España",
  trailer: "https://www.youtube.com/watch?v=E-jQCWPRHmA",
  sinopse:
    "Nacho (Fernando Tejero) é fotógrafo. Pilar, xornalista (Malena Alterio). Ódianse. Con todo, terán que facerse pasar por unha parella para realizar unha reportaxe sobre Olmo (Diego Peretti), un gurú que resolve as crises de parella facendo o Camiño de Santiago. Durante seis días de viaxe a través de Galicia, veranse envolvidos en todo tipo de situacións absurdas, delirantes e románticas... e é que xa se sabe que ata o final do camiño todo pode ocorrer.",
  poster: alfinaldelcaminoPoster,
}

export const footprints = {
  titulo: "Footprints, el camino de tu vida",
  director: "Juan Manuel Cotelo",
  ano: 2016,
  duracion: 89,
  xenero: "Documental",
  pais: "España",
  trailer: "https://www.youtube.com/watch?v=1ZlOyD8fPwM",
  sinopse:
    "Once mozos do deserto de Arizona viaxan ata España para realizar o Camiño de Santiago. Durante 40 días e 1000 km, este documental acompáñalles na maior aventura das súas vidas: unha viaxe física e espiritual capaz de cambiar as súas vidas para sempre.",
  poster: footprintsPoster,
}

export const oApostolo = {
  titulo: "O Apostolo",
  director: "Fernando Cortizo",
  ano: 2012,
  duracion: 72,
  xenero: "Animación",
  pais: "España",
  trailer: "https://www.youtube.com/watch?v=SmX_79VA4X4",
  premios: [
    "2012: Premios Goya: Nomeada a Mellor película de animación.",
    "2012: Festival de Sitges: Nomeada a Mellor película de animación.",
    "2013: Festival de Annecy: Premio do Público.",
  ],
  sinopse:
    "Un preso recentemente fuxido do cárcere tenta recuperar o botín que escondeu uns anos antes nunha remota e solitaria aldea; pero o que alí se atopa supón unha condena peor que o cárcere. Sinistros anciáns, estrañas desaparicións, espíritos, un peculiar sacerdote e ata o mismísimo arcipreste de Santiago protagonizan unha historia de terror, humor e fantasía. Primeira produción de stop- motion con plastilina feita en España. Conta coas voces de Luís Tosar, Geraldine Chaplin, Manuel Manquiña, Jorge Sanz e Paul Naschy entre outros.",
  poster: oapostoloPoster,
}
