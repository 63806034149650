import fotoCandido from '../images/poetas/candido.jpg';
import fotoCarolina from '../images/poetas/carolina.jpg';
import fotoLua from '../images/poetas/lua.jpg';
import fotoLuz from '../images/poetas/luz.jpg';
import fotoXulia from '../images/poetas/xulia.jpg';

export const luz = {
  nome: "Luz Lameiro Eiro (Luz)",
  bio: "Luz Lameiro Eiro, conocida en el mundo poético simplemente como Luz, se define a si misma como alguien que escribe con el corazón. Esta poeta de la tierra, mujer guerrera y aguerrida, amante además de las camelias, dice que desde siempre se ha sentido identificada con la poesía, y se ha servido de ella como medio de expresión de los sentimientos más íntimos, como el sonido del alma, que lucha por salir al exterior pero que al mismo tiempo quiere pasar desapercibida.",
  foto: fotoLuz,
  xenero: "Poetisa",
}

export const candido = {
  nome: "Cándido Duro Domínguez",
  bio: "Pontecesures 1965.",
  foto: fotoCandido,
  xenero: "Poeta",
  obras: [
    "Breixo. Monólogo dun traballador baixo a choiva (1989)",
    "O xardín das matanzas (1996)",
    "Rompendo os adivais da gorxa (2001)",
    "Só palabras como garabullos (2008)",
    "Manual de astronavegante (2013)",
    "Luz do abrente outonizo (2021)",
  ],
}

export const xulia = {
  nome: "Xulia María Barros",
  bio: 'Escritora e terapeuta. Como escritora: Publicou o poemario "Carne y Ceniza", a obra infantil "Cumba Maxia da Terra" representada en xira pola compañía de monicreques "Pequeñoles" e  o libro infantil-xuvenil  "A Árbore dos Vagalumes". Como intérprete musical: Lanzou o disco "A porta Aberta", e realizou diferentes espectáculos poético-musicais que a levaron aos distintos escenarios da nosa terra. En América Latina, por Ex ,, foi convidada  ao "Festival Internacional de Poesía da Habana" en Cuba e tamén ao "Encontro de poetas coa xente" dentro do Gran Festival de Cosquín. Como terapeuta e adestrador: Ten unha práctica privada e é facilitadora de obradoiros  sobre Crecemento Persoal e o Eneagrama da personalidade.  Desenvolve o seu traballo a través da psicoloxía, a música, a arte e a poesía. Estudou psicoloxía na UNED, certificado en Coach  Ontolóxico, Educación Emocional, mestra de Reiki  Usui e formouse en Eneagrama de personalidade e Psicoloxía dos subtipos,(Eneagrama)  no programa Sat do recoñecido doutor e profesor Claudio Naranjo.',
  foto: fotoXulia,
  xenero: "Poetisa",
}

export const lua = {
  nome: "Lúa Vila",
  bio: "Lúa Vila nace en Pontedeume o 25 de abril de 1991. A súa andaina polas letras comeza dende ben pequena, cando súa avoa lle agasalla un diario no que poder escribir todolos pensamentos que voan na súa cabeza. É unha apaixoada das letras e do arte, e iniciase na poesía tendo como referentes claros a Celso Emilio Ferreiro e a poesía de posguerra. Actualmente, compaxina os seus versos coa carreira de Historia do Arte. Participa activamente en proxectos e certames poéticos, cun estilo propio onde pon en manifesto a necesidade de cuestionar o imposto e o asumido e facelo loita. Na súa páxina de Instagram @todatoxo publica de maneira asidua algún poema con debuxos sinxelos, tamén, da súa man. Ela se define como unha poeta feminista, cun discurso orientado á súa xeración e consciente do papel subversivo que quere mostrar coa súa voz. Como derradeiro recoñecemento poético, foi a gañadora do II certame de poesía do Concello de Cabanas, que se celebrou con motivo das Letras Galegas este mesmo ano, na categoría adulta.",
  foto: fotoLua,
  xenero: "Poetisa",
}

export const carolina = {
  nome: "Carolina Sertal",
  bio: "Carolina Sertal (Redondela, 1989) é unha xornalista, escritora e poeta da vila dos viaductos que tras participar no proxecto de recuperación de memoria histórica do Obradoiro Estudos Locais Fernando Monroy, coa publicación de ‘A memoria dun pobo’, debutou como poeta en 2019 coa publicación do seu primero poemario,‘Anguria’. En 2014, resultou gañadora do 2o premio do Certame de relato curto convocado pola asociación Mulleres Progresistas de Vigo, co microrrelato titulado ‘Dsineb’. En 2018, acadou 1o premio de poesía nos II Premios Xuventude da Deputación de Pontevedra, cos versos de ‘Anguria’ e, o pasado mes de decembro de 2020, foi recoñecida co 2o premio de poesía nos IV Premios Xuventude da Deputación de Pontevedra, co poema ‘Estatuas de sal’.",
  foto: fotoCarolina,
  xenero: "Poetisa",
}
