import * as React from 'react';

const Poeta = ({ nome, bio, xenero, foto, obras }) => {
  return (
    <div className="poeta">
      <h1>
        {nome}
        <span>{xenero}</span>
      </h1>
      {foto && <img src={foto} alt={nome} />}
      <p>{bio}</p>
      {obras && (
        <ul>
          {obras.map((obra) => {
            return <li key={obra}>{obra}</li>
          })}
        </ul>
      )}
    </div>
  )
}

export default Poeta
