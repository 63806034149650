import * as React from 'react';

const Parroquia = ({ id, nome, concello, data, hora, local, entradas }) => {
  return (
    <div className="parroquia">
      <h1>
        {nome}
        <span>{concello}</span>
      </h1>
      <h2>
        {data} {hora}
      </h2>
      <h2>{local}</h2>
      <h3>{entradas}</h3>
    </div>
  )
}

export default Parroquia
