import { alFinalDelCamino, footprints, oApostolo, oCamino, viaLactea } from './filmes.data';
import { cesantes, iriaFlavia, pontecesures, redondela, tui } from './parroquias.data';
import { candido, carolina, lua, luz, xulia } from './poetas.data';

const eventos = [
  { parroquia: iriaFlavia, filme: oApostolo, poeta: lua },
  { parroquia: redondela, filme: footprints, poeta: carolina },
  { parroquia: pontecesures, filme: alFinalDelCamino, poeta: candido },
  { parroquia: cesantes, filme: oCamino, poeta: xulia },
  { parroquia: tui, filme: viaLactea, poeta: luz },
]

export default eventos
